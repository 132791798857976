<template>
  <div>
    <el-button type="success" :disabled="$store.state.userRoles !== 'ADMIN'" 
      @click="showaddImageModal"
      round>Add Image</el-button>
    <article>
      <section>
        <div class="courseUnits_dialogs">
          <el-dialog
            :title="`Add Image`"
            :visible.sync="addImageModal"
            :destroy-on-close="true"
            :before-close="handleClose"
            style="text-align: left"
          >
            <div v-loading="submitting">
              <el-form
                :model="imageForm"
                :rules="rules"
                label-position="top"
                ref="imageForm"
                class="demo-imageForm2"
              >
                <div class="row">
                  <div class="col-md-12">
                    <el-form-item
                      label="banner Title"
                      prop="imageTitle"
                    >
                      <el-input v-model="imageForm.imageTitle"></el-input>
                    </el-form-item>
                  </div>
                </div>
                <!-- <div class="row">
                  <div class="col-md-12">
                    <el-form-item
                      label="banner Brief Description"
                      prop="banner_brief_description"
                    >
                      <el-input v-model="imageForm.banner_brief_description"></el-input>
                    </el-form-item>
                  </div>
                </div> -->
                <div class="row">
                  <div class="col-md-6">
                    <el-form-item label="Banner Photo">
                      <el-upload
                        class="upload-demo"
                        drag
                        action="''"
                        :on-change="handleBannerPhotoPreview"
                        :on-remove="handleBannerPhotoRemove"
                        :file-list="bannerPhotoFileLists"
                        :auto-upload="false"
                        :multiple="false"
                      >
                        <div v-if="!isUploadingFile">
                          <i class="el-icon-upload"></i>
                          <div class="el-upload__text">
                            Drop file here or <em>click to upload</em>
                          </div>
                        </div>
                        <div v-else>
                          <el-progress
                            v-if="imageUploadingProgress != 100"
                            type="circle"
                            :color="progressColors"
                            :percentage="imageUploadingProgress"
                            :width="80"
                            :stroke-width="4"
                          ></el-progress>
                          <el-progress
                            v-else
                            type="circle"
                            :percentage="100"
                            status="success"
                            :width="80"
                            :stroke-width="4"
                          ></el-progress>
                          <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                            {{
                          imageUploadingProgress == 100
                            ? "Uploaded"
                            : "Uploading..."
                        }}
                          </div>
                        </div>
                        <!-- <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div> -->
                      </el-upload>
                    </el-form-item>
                  </div>
                </div>

                <hr />
                <el-form-item class="text-center">
                  <el-button
                    style="background-color: #083628; color: white;"
                    @click="addImage('imageForm')"
                  >Add Image</el-button>
                </el-form-item>
              </el-form>

              <div class="text-center">
                <div class="text-center">
                  <small>© 2022 Acaki Lodge, All rights reserved.</small>
                </div>
                <div class="text-center">
                  <!--v-model="imageForm.Course" <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                </div>
              </div>
            </div>
          </el-dialog>
        </div>
      </section>
    </article>
  </div>
</template>
<script>
export default {
  data() {
    return {
      banners: [],
      selectedBannerPhotoFile: null,
      isUploadingFile: false,
      imageUploadingProgress: 0,
      progressColors: [
        { color: "#f56c6c", percentage: 25 },
        { color: "#e6a23c", percentage: 50 },
        { color: "#1989fa", percentage: 75 },
        { color: "#6f7ad3", percentage: 100 },
      ],
      bannerPhotoFileLists: [],
      addImageModal: false,
      submitting: false,
      imageForm: {
        imageTitle: "",
        banner_brief_description: "",
      },
      rules: {
        imageTitle: [
          {
            required: true,
            message: "banner Title is required",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    handleClose(done) {
      done();
    },
    handleBannerPhotoPreview(file) {
      console.log(file.raw);
      this.bannerPhotoFileLists = [file];
      this.selectedBannerPhotoFile = file.raw;
    },
    handleBannerPhotoRemove() {
      this.selectedBannerPhotoFile = null;
    },
    showaddImageModal() {
      this.addImageModal = true;
      this.imageForm.imageTitle = "";
    },
    async addImage(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.submitting = true;
            this.isUploadingFile = true;
            const formData = new FormData();
            formData.append("imageTitle", this.imageForm.imageTitle);
            formData.append("image_url", this.selectedBannerPhotoFile);
            formData.append("createdBy", this.$store.state.userId);
            let response = await this.$http.post(`gallery`, formData, {
              onUploadProgress: (progressEvent) => {
                this.imageUploadingProgress = +(
                  (progressEvent.loaded / progressEvent.total) *
                  100
                ).toFixed(0);
              },
            });
            if (
              response.data.success &&
              response.data.message == "IMAGE_CREATED_SUCCESSFULLY"
            ) {
              this.$refs[formName].resetFields();
              this.$emit("re-fresh");
              this.$notify({
                title: "Success",
                message: "Banner added successfully",
                type: "success",
              });
              this.addImageModal = false;
            } else {
              throw "UNEXPECTED_RESPONSE";
            }
          } catch (error) {
            if (error.message == "Network Error") {
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred, please try again.",
                type: "error",
              });
            }
            this.$notify({
              title: "Unable to add Banner",
              message: "An unexpected Error occurred, please try again",
              type: "error",
            });
          } finally {
            this.submitting = false;
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>